<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import reportsMixin from '@/modules/Reports/reportsMixin'
import { solutionTypeFormatter } from '@/support/utils/solutionTypeFormatter'

export default {
  name: 'ReportEnrollmentList',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datepicker: () => import('@/components/general/Datepicker'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  mixins: [ reportsMixin ],
  data () {
    return {
      formData: {
        dateInit: '',
        dateEnd: '',
        solutionType: null,
        solutions: [],
        learningPath: [],
        reportId: null
      },

      solutionTypeList: [],
      allSolutionsList: [],
      filteredSolutionsList: [],
      paths: [],
      modalWarning: false
    }
  },

  computed: {
    solutionsListPaginated () {
      return this.$store.getters.solutionsList
    }
  },

  validations: {
    formData: {
      solutionType: {
        required
      },

      solutions: {
        required
      },

      dateInit: {
        required
      },

      dateEnd: {
        required
      }
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([
      this.getSolutionsTypesList(),
      this.getLearningPathsList()
    ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSolutionsTypesList',
      'attemptGetSolutionsList',
      'attemptGetLearningPathsList',
      'attemptGetReportListEnrollments',
      'attemptCheckReport',
      'attemptReportDownload'
    ]),

    getReportEnrollmentList () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const initDate = moment(this.formData.dateInit)
      const finalDate = moment(this.formData.dateEnd)

      if (finalDate.diff(initDate, 'days') > 31) {
        this.modalWarning = true
      } else {
        this.generateReport()
      }
    },

    generateReport () {
      this.modalWarning = false
      const params = {
        createdDateInit: this.formData.dateInit,
        createdDateEnd: this.formData.dateEnd,
        solutionIds: this.formData.solutions
      }

      this.setFetching(true)

      this.attemptGetReportListEnrollments(params).then((response) => {
        this.reportId = response.data.id
        this.checkReport()
      }).catch(() => {
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      })
    },

    getSolutionsTypesList () {
      this.setFetching(true)
      const pagination = {
        filter: {
          active: true
        },

        order: {
          name: 'asc'
        }
      }

      this.attemptGetSolutionsTypesList(pagination).then(pagination => {
        if (pagination.data) {
          const solutionTypes = pagination.data

          // TODO: filter temporário para remover a solução Canvas no ambiente de homologação.
          // solutionTypes = solutionTypes.filter((value, index) => index !== 0)

          solutionTypes.forEach(element => {
            this.solutionTypeList.push({
              text: solutionTypeFormatter(element),
              value: element.alias,
              id: element.id
            })
          })

          return true
        }
      }).finally(() => {
        this.setFetching(false)
      })
    },

    getSolutions (pagination) {
      return new Promise((resolve) => {
        this.attemptGetSolutionsList(pagination).then((paginationResponse) => {
          this.allSolutionsList.push(...this.solutionsListPaginated)

          if (pagination.page < paginationResponse.lastPage) {
            pagination.page += 1
            resolve(this.getSolutions(pagination))
          } else {
            resolve()
          }
        })
      })
    },

    getLearningPathsList () {
      return this.attemptGetLearningPathsList({ limit: 300 }).then(pagination => {
        if (pagination.data) {
          this.paths = pagination.data.map((element) => {
            return {
              text: element.name,
              value: element.id,
              solutions: element.learningPathSolutions
            }
          })
        }
      })
    },

    getSolutionsLearningPath (learningPath) {
      learningPath.solutions.map((solution) => {
        this.formData.solutions.push(solution.solution.id)

        return solution
      })
    },

    getSolutionsByType (solutionType) {
      this.filteredSolutionsList = []
      this.allSolutionsList = []
      this.formData.solutions = []
      this.formData.learningPath = []

      if (!this.formData.solutionType) {
        return
      }

      const solutionsfilter = { solution_type: solutionType.id }

      this.setFetching(true)

      this.getSolutions({
        filter: solutionsfilter,
        order: { name: 'ASC' },
        limit: 9999,
        page: 1
      }).finally(() => {
        this.allSolutionsList.forEach((solution) => {
          this.filteredSolutionsList.push({
            text: solution.name,
            value: solution.id
          })
        })

        this.setFetching(false)
      })
    },

    closeModal () {
      this.modalWarning = false
    },

    leave () {
      this.$router.push({ name: 'reports.index' })
    },

    downloadReport () {
      this.attemptReportDownload(this.reportId).then((response) => {
        const url = window.URL.createObjectURL(new Blob([ response.data ]))
        const link = document.createElement('a')

        link.href = url

        link.setAttribute(
          'download',
          this.$t('reports.generate:report.enrollments.list.file.name').toString()
        )

        document.body.appendChild(link)
        link.click()
      })
    },

    checkReport () {
      const interval = setInterval(() => {
        this.attemptCheckReport(this.reportId).then((response) => {
          if (response.data.status === 'generated') {
            this.setFetching(false)
            this.downloadReport()
            clearInterval(interval)
          } else if (response.data.status === 'failed') {
            this.setFetching(false)
            this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
            clearInterval(interval)
          }
        })
      }, 5000)
    }
  }
}
</script>

<template>
  <div class="main-content reports-create">
    <ContentHeader
      :title="$t('reports:report.enrollments.list.title')"
      light-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.reports')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />
      <ActionBar slot="actionbar" />
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="solutions-create-header">
          <h2
            class="solutions-create-title"
            v-html="$t('reports.generate:header.description')"
          />
        </div>
        <form class="form">
          <FormSection :title="$t('global:form.filters')">
            <SelectField
              v-model="formData.solutionType"
              :label="$t('global:form.solutions.type')"
              :items="solutionTypeList"
              :validation="$v.formData.solutionType"
              @change="getSolutionsByType"
            />
            <div v-if="formData.solutionType">
              <SelectField
                v-model="formData.learningPath"
                :label="$t('global:form.learning.path')"
                :items="paths"
                multiple
                :under-description="$t('reports.generate:form.path.under.description')"
                :select-all-option="true"
                :searcheable="true"
                @change="getSolutionsLearningPath"
              />
              <SelectField
                v-model="formData.solutions"
                :label="$t('global:form.solution')"
                :items="filteredSolutionsList"
                :validation="$v.formData.solutions"
                multiple
                :select-all-option="true"
                :searcheable="true"
              />
              <Datepicker
                v-model="formData.dateInit"
                :label="$t('global:form.filters.date.init')"
                :validation="$v.formData.dateInit"
              />
              <Datepicker
                v-model="formData.dateEnd"
                :label="$t('global:form.filters.date.end')"
                :validation="$v.formData.dateEnd"
              />
            </div>
          </FormSection>
          <Action
            slot="button"
            primary
            large
            fixed-width
            type="button"
            :text="$t('reports.generate:btn.generate')"
            @click="getReportEnrollmentList"
          />
        </form>
      </div>
    </div>
    <ModalConfirm
      :active="modalWarning"
      :title="$t('reports:report.enrollments.list.title')"
      :description="$t('reports.generate:report.modal.description')"
      @cancelAction="closeModal"
      @confirmAction="generateReport"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/solutions.css"></style>

<style lang="scss" scoped>
.main-content .main-content-inner {
  padding-top: 120px;
}
</style>
